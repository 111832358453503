<template >
<div class="header">
  myVine
</div>
</template>

<script>
export default {
  name: "app-header"
}
</script>

<style scoped>
.header {
  width: 100vw;
  height: 80px;
  background-color: var(--dark);
  color: var(--light);
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}
</style>