<template >
<div class="footer">
  {{ text }}
</div>
</template>

<script>
export default {
  name: "app-footer",
  data() {
    return {
      text: 'Check this voice'
    };
  },
}
</script>

<style lang="scss" scoped>
  .footer {
    width: 100vw;
    height: 80px;
    background-color: var(--dark);
    color: var(--light);
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
  }
</style>
