import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBq963AtzqtwVfCmHYlF-mt_oMYZo2Tu4Y",
    authDomain: "myvine-back.firebaseapp.com",
    projectId: "myvine-back",
    storageBucket: "myvine-back.appspot.com",
    messagingSenderId: "30427000672",
    appId: "1:30427000672:web:6932f8b39839b11f7cf805",
    measurementId: "G-N58YE04JS6"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export function onLogin(email, pass) {
    const auth = getAuth();
    return signInWithEmailAndPassword(auth, email, pass)
        .then((userCredential) => {
            return userCredential.user;
        })
        .catch((error) => {
            console.log({
                errorCode: error.code,
                errorMessage: error.message
            }, 'error');
            // Бросаем ошибку, чтобы обработать ее в методе onLogin компонента
            throw error;
        });
}