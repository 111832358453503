<template>
  <div class="card">
    <div class="card__name">
      {{ name }}
    </div>

    <div class="card__description">
      {{ descriptionValidated }}
    </div>
  </div>
</template>

<script>
export default {
  name: "vine-card",
  props: ['name', 'description'],
  computed: {
    descriptionValidated() {
      return this.description ?? 'Тут будет описание объекта';
    }
  }
}
</script>

<style lang="scss" scoped>
  .card {
    background-color: var(--primary);
    color: var(--light);
    height: 200px;
    padding: var(--spacer-s);
    border-radius: 12px;
    &__name {
      font-weight: 700;
      margin-bottom: var(--spacer-s);
    }
    &__description {
      font-weight: 500;
    }
  }
</style>