<template>
  <div class="wrapper">
    <Header/>
    <div class="content">
      <div class="vines" v-if="user">
        <div class="vines__card" v-for="card in testArray"
             :key="card">
          <VineCard
              :name="card.name"
              :description="card.description"/>
        </div>
      </div>
      <div v-else @click="onLogin">
        login
      </div>
    </div>
    <div class="footer">
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from './components/blocks/app-header';
import Footer from './components/blocks/app-footer';
import VineCard from "./components/Ui/vine-card";
import { onLogin } from "@/helpers/firebase";

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    VineCard
  },
  data() {
    return {
      testArray: [
        {
          name: 'Вишня',
          description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.' +
              ' Aperiam consectetur ea est in ipsam iure neque nisi, provident ullam veniam voluptas voluptates?'
        },
        {name: 'Виноград'},
        {name: 'Секретный рецепт'},
        {name: 'Дедовский метод'}
      ],
      user: null
    };
  },
  methods: {
    async onLogin() {
      try {
        this.user = await onLogin('tonymontana@yandex.ru', '123456');
        if (this.user) {
          this.user = this.user.email
        }
      } catch (error) {
        console.error(error);
      }
    }
  }
}

</script>

<style lang="scss">
  @import "./styles/normalize.css";
  @import "./styles/variables.css";
  body {
    height: 100%;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .content {
    display: flex;
    flex: 1;
    background-color: var(--light);
    padding: var(--spacer-m);
  }

  .vines {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: var(--spacer-m);
    height: max-content;
    &__card {
      flex: 1 1 30%;
    }
  }
  .footer {
    display: flex;
    flex-shrink: 0;
  }

  #app {
    height: 100%;
  }
</style>
